import './App.css';
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import React, { useState } from 'react';
import { Button, Container, Divider, Grid, Header, Icon, Image, Segment } from 'semantic-ui-react';

const App = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [processingImage, setProcessingImage] = useState(false);
  const [outputImage, setOutputImage] = useState(null);
  const [logs, setLogs] = useState([]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setProcessingImage(true);

    // Simulating image processing time
    setTimeout(() => {
      const filePath = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageData = e.target.result;
        fetch("http://10.101.118.173:5000/file", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/octet-stream',
            'Access-Control-Allow-Origin': '*'
          },
          body: imageData
        })
        .then(response => response.json())
        .then(response => {
          // Decode the Base64 image
          let decodedImage = atob(response.image);

          // Create a Uint8Array from the decoded image data
          let imageArray = new Uint8Array(decodedImage.length);
          for (let i = 0; i < decodedImage.length; i++) {
            imageArray[i] = decodedImage.charCodeAt(i);
          }

          // Create a Blob object from the Uint8Array
          let blob = new Blob([imageArray], { type: 'image/jpeg' });

          // Use the blob as needed, e.g., to display the image
          let imageURL = URL.createObjectURL(blob);
                
          setProcessingImage(false);
          setOutputImage(imageURL);

          let log = "Image processing completed";
          if(response.detection_data.length === 0){
            log += ": no objects were detected."
          }else{
            log += ": detected " + response.detection_data.length + " object(s) - "

            let logs = [];
            for(let detection of response.detection_data){
              logs.push("confidence=" + detection.confidence + ", class_name=" + detection.class_name);
            }

            log += logs.join(" | ")
          }

          addLog(log);
        });
      };

      reader.readAsDataURL(filePath);
    }, 2000);
  };

  const addLog = (log) => {
    const timestamp = new Date().toLocaleTimeString();
    const date = new Date().toLocaleDateString();
    const formattedLog = `${timestamp} ${date}: ${log}`;
    setLogs((prevLogs) => [...prevLogs, formattedLog]);
  };

  const exportLogs = () => {
    const logText = logs.join('\n');
    const element = document.createElement('a');
    const file = new Blob([logText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'logs.txt';
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div>
      <Header as="h1" textAlign="center" style={{ paddingTop: '1rem' }}>
        RPDSUCV
      </Header>
      <Container textAlign="center" style={{ marginTop: '2rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <Segment basic textAlign="center">
                {selectedImage && (
                  <Image src={selectedImage} size="medium" centered style={{ marginBottom: '1rem' }} />
                )}
              <input type="file" accept="image/*" onChange={handleImageUpload} />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment basic textAlign="center">
                {processingImage ? (
                  <div>
                    <Icon name="spinner" size="big" loading />
                    <Header as="h3">Processing Image...</Header>
                  </div>
                ) : (
                  outputImage && <Image src={outputImage} size="medium" centered style={{ marginBottom: '1rem' }} />
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <Divider />
        <Header as="h3">Logs</Header>
        {logs.map((log, index) => (
          <p key={index}>{log}</p>
        ))}
      </Container>

      <Container textAlign="center" style={{ marginBottom: '2rem' }}>
        <Button primary fluid onClick={exportLogs}>
          Export Logs
        </Button>
      </Container>
    </div>
  );
};

export default App;




// function App() {
//   const onSelectFile = event => {
//     const filePath = event.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (e) => {
//       const imageData = e.target.result;
//       fetch("http://127.0.0.1:5000/file", {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/octet-stream', // Set the appropriate content type
//           'Access-Control-Allow-Origin': '*'
//         },
//         body: imageData // imageData should contain the serialized image data      
//       })
//       .then(response => response.blob())
//       .then(blob => {
//         // Create an object URL for the blob
//         const imageURL = URL.createObjectURL(blob);
        
//         // Create an <img> element and set its src attribute
//         const imgElement = document.getElementById('image-display');
//         imgElement.src = imageURL;
//       });
//     };

//     reader.readAsDataURL(filePath);
//   };
//   return (
//     <div className="App">
//       <header className="App-header">
//         <Input placeholder='Search...' input={{type: "file", onChange: onSelectFile}} />
//         <img id='image-display' />
//       </header>
//     </div>
//   );
// }

// export default App;
